.IR_aboutUs {
    div.hero {
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        background: $c-pink-f0;
        padding-top: 90px;
        display: flex;
        align-items: center;
        flex-direction: column;
        #backgroundImage{
            position: absolute;
            bottom: 10%;
            right: 0;
            width: 90%;

            pointer-events: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
        @include for-phone {
            padding-top: 67px;
        }
        .hero-text{
            // padding: 0 15px;
            margin-top: 60px;
            width: 100%;
            max-width: 1166px;
            z-index: 99;
            .image-wrapper{
                padding: 0 25px;
                margin-bottom: 50px;
                img{
                    width: 100%;
                }
            }
        }
    }
    .client-wrapper {
        margin-top: 100px;
    }
    .IR_clientCarousel {
        padding-left: 15px;
    }
    .paragraphWrapper {
        margin: 38px 53px 100px 153px;
    }
    @include for-tablet {
        .paragraphWrapper{
            margin: 70px 18px 100px 15px;
        }
    }

    .interestedSection {
        margin: 100px 15px 0px;
        // margin-top: 100px;
        // margin-bottom: 100px;
        @include for-phone{
          margin-top: 60px;
        }
      }

}