.IR_singleIrWidget{

    .name_and_version_wrapper{
        display: flex;
        flex-direction: row-reverse;
        padding-left: 9px;
        align-items: center;
        justify-content: flex-end;

        &.mobile {
            display: none;
            @include for-tablet {
                display: flex;
            }
        }
        &:not(.mobile){
            @include for-tablet {
                display: none;
            }
        }
        h1.widgetName {
            font-weight: 700;
            font-size: 40px;
            line-height: 34px;
            color: $c-dark-23;
            text-transform: capitalize;

            
            div.backButton{
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 30px;
                
                margin-bottom: 30px;
                font-size: 16px;
                line-height: 34px;
            }
        }
        div.toggleWrapper{
            display: flex;
            @include for-phone {
                padding-left: 32px;
            }
            label.viewType{
                font-weight: 400;
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.06em;
                color: $c-white;
            }
            .versionCheck{
                top: 5px;
                z-index: 99;
                position:absolute;
    
                &.v2{
                    right: -5px;
                }
            }
        }
    }
    background-color: $c-green-e9;
    a:visited{ color: $c-dark-23 }
    .titleWrapper{
        display: flex;
        margin-bottom: 25px;
        overflow: hidden;
        padding: 0 4px;
        column-gap: 25px;

        .backButton{
            flex: 2 1;
            .backText{
                margin-left: 10px;
            }
        }
        .customizeText{
            flex: 1 1;
            padding-left: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            @include for-tablet {
                display: none;
            }
        }
        .widgetName{
            display: none;
        }
    }
    
    div.container{
        padding-bottom:80px;
        display: flex;
        flex-direction: row;
        gap:25px;

        .backButton{
            margin-bottom: 10px;
            span.backText{
                margin-left: 10px;
            }
        }
        div.left_section{
            flex: 2;
            overflow: hidden;
            padding: 0 4px;
            #widgetBox{
                margin: 30px 0 85px
            }
            
                
            .widgetCode {
                color: $c-dark-23;
                &:not(:last-child){
                    margin-bottom: 37px;
                }
                .widgetCodeHeader {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 25px;
                    margin-bottom: 20px;
                }
                .widgetCodeDescription{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                }
                .copyDescription {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 25px;
                    color: $c-gray-6e;
                    display: block;
                    margin-top: 18px;
                    margin-bottom: 5px;
                }
                .clipboardContainer{
                    border: 2px solid #D2D2D2;
                    border-radius: 6px;
                    background: $c-white;
                    // width: 100%;
                    overflow-x: scroll;
                    max-width: 900px;

                    pre {
                        padding: 15px;
                        margin: 0;
                        width: fit-content;
                    }
                }
            }
        }
        div.right_section{
            flex: 1;
            user-select: none;
            text-transform: capitalize;
            
            h3.option__title{
                margin-bottom: 20px;
            }

            .optionsWrapper {
                background-color: $c-white;
                border-radius: 20px;
                padding: 25px;
                height: fit-content;
                
                .dropDownSelectorWrapper{
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;
                }
                .toggleWrapper{
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 33px;
                    row-gap: 17px;
                    .option__toggle{
                        flex-basis: 45%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                            font-size: 14px;
                        }

                        @media (max-width: 1250px) {
                            flex-basis: 100%;
                        }

                        //smaller toggle styling
                        .react-toggle{
                            align-items: center;
                            justify-content: center;

                            .react-toggle-track {
                                position: unset;
                                right: 8px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 16px;
                                width: 34px;
                                border-radius: 98px;
                                background-color: $c-gray-d9;
                            }

                            .react-toggle-thumb {
                                top: -3px;
                                left: -3px;
                                width: 22px;
                                height: 22px;
                                background-color: $c-gray-9d;
                                border: none;
                            }
                            
                        }
                        .react-toggle--checked{
                            .react-toggle-track {
                                left: 14px;
                                background-color: rgba(32, 154, 160, 0.4);;

                            }
                            .react-toggle-thumb {
                                left: 14px;
                                background-color: $c-green-20;
                            }
                        }
                    }
                }
                &.mobile {
                    display: none;
                    div.option__borderSize{
                        margin-top: 15px;

                        
                    }
                }
                div.option__borderSize{
                    display: flex;
                    align-items: center;
                    column-gap: 27px;
                    margin-top: 15px;
                    
                    label{
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 25px;
                        color: $c-dark-23;
                    }

                    &.disabled {
                        label{
                            color: $c-gray-9d;
                        }
                    }
                }
    
                .optionTitle {
                    margin-top: 15px;
                }
                .checkboxWrapper{
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 15px;
                    margin-bottom: 25px;

                    @include for-tablet {
                        margin-bottom: 0px;
                    }
                    
                    .option__checkbox{
                        flex-basis: 50%;
                    }
                }
    
                .colorsWrapper{
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 15px;
                    column-gap: 28px;
                    .option__color{
                        flex: 2;
                    }
                }
            }

        }
    }

    @include for-tablet {
        .titleWrapper{
            flex-direction: column;
            row-gap: 10px;
            .widgetName{
                text-transform: capitalize;
                display: block;
            }
            @include for-phone {
                .widgetName{
                    font-size: 30px;
                    line-height: 40px;
                }
            }
        }
        div.container{
            flex-direction: column-reverse;
            
            div.left_section #widgetBox {
                margin-bottom: 35px;
            }
            
            div.optionsWrapper.desktop {
                display: none;
            }

            div.optionsWrapper.mobile {
                // display: block !important;
                background: none;
                display: flex !important;
                flex-direction: column;
                padding: 0;
                margin-top: 0px;
                row-gap: 5px;

                .dropdownBox {
                    background: $c-white;
                    display: flex;
                    flex-direction: column;
                    padding: 4px 20px 7px; 
                    border-radius: 10px;
                    cursor: pointer;
                    
                    .dropdownBox_head {
                        font-weight: 700;
                        transition: all 5s;
                        font-size: 12px;
                        line-height: 34px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .fa-icon {
                            width: 14px;
                            height: 25px;
                        }
                    }
                    .settingsWrapper {
                        display: flex;
                        flex-direction: column;
                        row-gap: 20px;
                        padding-bottom: 30px;

                        &.colorsWrapper{
                            flex-direction: row;
                            flex-wrap: wrap;

                        }

                        .checkboxWrapper{
                            flex-wrap: wrap;
                            .option__checkbox{
                                flex: 2 25%;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
        
    }
}

@keyframes rotateMenu {
    0% {
        transform: rotateX(-90deg)
    }
    70% {
        transform: rotateX(20deg) 
    }
    100% {
        transform: rotateX(0deg) 
    }
}