.IR_contact {
  background: linear-gradient(180.02deg, #7720A0 25.9%, #232530 41.53%);

  .formContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 15px;
  }

  .contact-smallPrint-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 902px;
    gap: 50px;
    margin-top: 50px;
    margin-bottom: 130px;
    flex-wrap: wrap;
    @include for-phone {

      gap: 40px;
      margin-bottom: 0px;
    }
    .smallPrint-wrapper{
      display: flex;
      @include for-phone {
        flex-direction: column;
        gap: 40px;
      }
      .small-print {
        flex: 1;
      }
    }
  }

  .contact-form {
    .inputWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      margin-bottom: 25px;

      @include for-phone {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}