.IR_input {
    position: relative;
    display: flex;
    flex: 1;
    input,textarea {
        width: 100%;
        flex-basis: 100%;
    }
    textarea{
        min-height: 100px;
        resize: vertical;
    }
    .form-control {
        font-family: 'Poppins';
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
    }
        
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $c-gray-52 !important;
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $c-gray-52 !important;
    }
    
    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $c-gray-52 !important;
    }
  
    label {
        position: absolute;
        top: 12px;
        left: 15px;
        padding-left: 8px;
        pointer-events: none;
        transition: 300ms ease all;
    
        color: $c-gray-52;
        font-weight: 400;
        font-size: 15px;
    }
  
    input.form-control:focus,
    textarea.form-control:focus,
    select.form-control:focus {
      outline: none;
    }

    input:focus ~ label.form-input-label,
    input:valid ~ label.form-input-label,
    input.has-value ~ label.form-input-label,
    textarea:focus ~ label.form-input-label,
    textarea:valid ~ label.form-input-label,
    select:focus ~ label.form-input-label,
    select:valid ~ label.form-input-label {
      background-color: $c-white;
      font-size: 10px;
      padding: 0 2px;
      top: -8px;
      left: 15px;
    }
    textarea.form-control:focus,
    select.form-control:focus,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="date"]:focus,
    input[type="month"]:focus,
    input[type="time"]:focus,
    input[type="week"]:focus,
    input[type="number"]:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="color"]:focus,
    .uneditable-input:focus {
        box-shadow: none;
        border-color: none;
        border: 1px solid #cfcfcf;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        background: $c-white;
    }

    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $c-white inset !important;
    }

    input.form-control,
    textarea.form-control,
    select.form-control {
        color: $c-gray-52;
        background: $c-gray-f8;
        border: 1px solid $c-gray-cf;
        border-radius: 30px;
        padding: 9px 23px;
    }
    textarea.form-control{
        border-radius: 20px;
    }
}