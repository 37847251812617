.IR_getStarted {
  background: linear-gradient(180.02deg, #209AA0 30.9%, #232530 46.53%);;

  .formContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 15px;
  }

  .getStarted-form {
    background: $c-white;
    border-radius: 20px;
    .inputWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      margin-bottom: 25px;

      @include for-phone {
        gap: 20px;
        flex-direction: column;
      }
    }
  }
  div.nasdaqOnly{
    color: $c-white;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin: 50px 0 100px;
    @include for-phone{
      margin-bottom: 0px;
    }
    .fa-icon {
      font-weight: 900;
      font-size: 18px;
      line-height: 25px;
      @include for-phone{
        margin-top: 3px;
      }
    }

    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
    }
  }
}