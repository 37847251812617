@import './variables.scss';
@import "react-toggle/style.css";

@import './Pages/AboutUs.scss';
@import './Pages/Contact.scss';
@import './Pages/Demos.scss';
@import './Pages/GetStarted.scss';
@import './Pages/HomePage.scss';
@import './Pages/IrSingleWidget.scss';
@import './Pages/IrWidgets.scss';
@import './Pages/Login.scss';
@import './Pages/NotFound.scss';
@import './Pages/OurWidgets.scss';
@import './Pages/Pricing.scss';
@import './Pages/Terms.scss';

@import './Components/Clients.scss';

@import './Ui-elements/ActiveButton.scss';
@import './Ui-elements/Alert.scss';
@import './Ui-elements/Burger.scss';
@import './Ui-elements/Button.scss';
@import './Ui-elements/Checkbox.scss';
@import './Ui-elements/ColorPicker.scss';
@import './Ui-elements/CookieConsent.scss';
@import './Ui-elements/DemoDropDown.scss';
@import './Ui-elements/DropDown.scss';
@import './Ui-elements/Footer.scss';
@import './Ui-elements/Header.scss';
@import './Ui-elements/HeaderLogo.scss';
@import './Ui-elements/HeadSection.scss';
@import './Ui-elements/InterestedModal.scss';
@import './Ui-elements/NumberInput.scss';
@import './Ui-elements/Input.scss';
@import './Ui-elements/ParagraphWithImage.scss';
@import './Ui-elements/PriceCard.scss';
@import './Ui-elements/SelectedDropDown.scss';
@import './Ui-elements/SmallPrint.scss';
@import './Ui-elements/ToastComp.scss';
@import './Ui-elements/ToggleTrack.scss';

body {
  margin: 0;
  font-family: 'Poppins' , 'Roboto';
  overflow-x: hidden;
  height: 100vh;
  // -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .rootWrapper{
    overflow-x: hidden;
  }

  //default disable text-dec
  a {
    text-decoration: none;
    font-family: 'Poppins';
    font-style: 'normal';
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,p {
  margin: 0px;
}

strong {
  font-weight: 700;
}
.jc-center {
  display: flex;
  justify-content: center;
}
.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
