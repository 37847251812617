.IR_ourWidgets {
    div.hero {
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        background: $c-green-e9;
        padding-top: 90px;
        display: flex;
        align-items: center;
        flex-direction: column;
        #backgroundImage{
            position: absolute;
            bottom: 10%;
            right: 0;
            width: 90%;

            pointer-events: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
        @include for-phone {
            padding-top: 67px;
        }
        .hero-text{
            margin-top: 60px;
            width: 100%;
            max-width: 1166px;
            z-index: 99;
            .image-wrapper{
                padding: 0 25px;
                margin-bottom: 50px;
                img{
                    width: 100%;
                }
            }
        }
    }

    .examples_section{
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 100px;
        margin-right: 15px;
        margin-left: 15px;
        .exampleImages{
            .browserImage{
                // border-radius: 10px 10px 5px 5px;
                // filter: drop-shadow(0px 4px 20px rgba(12, 10, 97, 0.45));
                @include for-phone {
                    width: 300px;
                }
            }
        }
    }
    //interested modal

    .interestedSection {
        margin-top: 100px;
        @include for-phone{
            margin-top: 60px;
        }
    }



}