.IR_clientCarousel {
    user-select: none;
    h5.title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */
        text-transform: uppercase;
        color: $c-green-20;
        margin-bottom: 15px;
    }
    .alice-carousel {
        margin-left: -15px;
    }
    .alice-carousel__stage{
        padding: 15px;
        
    }
    li.alice-carousel__stage-item{
        margin-right: 15px;
        cursor: pointer;
        
    }
    .alice-carousel__prev-btn{
        margin-right: 15px;
        margin-left: 15px
    }
    .alice-carousel__wrapper{
        overflow: visible;
    }
    .alice-carousel__prev-btn, .alice-carousel__next-btn {
        width: fit-content;
        padding: 15px 0px 0px;
        cursor: pointer;
        user-select: none;
        // img {

        // }
    }
    .single-company {
        height: 107px;
        width: 206px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(32, 154, 160, 0.1);
        border-radius: 10px;
        transition: background ease-in 0.15s, box-shadow ease-in 0.2s;
        &:hover{
            box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
            background: white;
        }
        img {
            pointer-events: none;
            // no ghost images on drag
            -webkit-user-drag: none; 
            -khtml-user-drag: none; 
            -moz-user-drag: none; 
            -o-user-drag: none; 
            user-drag: none; 
        }
    }
    @include for-phone {
        .single-company {
            height: 72.59px;
            width: 140px;
        }
        h5.title {
            font-size: 12px;
            line-height: 18px;
        }
        img:not(.carousel_btn) {
            width: 80%;
            height: 80%;
        }
    }
}