div.IR_headerLogo{
    // mobile not activated
    .normal-big-logo{
        display: block;
    }
    .normal-small-logo{
        display: none;
    }
    // screen is smaller than 640px
    @include for-phone {
        .normal-big-logo{
            display: none;
        }
        .normal-small-logo{
            display: block;
        }
    }
    .mobile-big-logo, .mobile-small-logo {
        display: none;
    }

    //mobile activated and screensize is smaller than 1010px
    @include for-tablet {
        &.mobile{
            .normal-big-logo, .normal-small-logo {
                display: none;
            }
            .mobile-big-logo{
                display: block;
            }
            .mobile-small-logo{
                display: none;
            }
            // screen is smaller than 640px
            @include for-phone {
                .mobile-big-logo{
                    display: none;
                }
                .mobile-small-logo{
                    display: block;
                }
            }
        }
    }

    &.white{
        .normal-big-logo, .normal-small-logo {
            display: none;
        }
        .mobile-big-logo{
            display: block;
        }
        .mobile-small-logo{
            display: none;
        }
        // screen is smaller than 640px
        @include for-phone {
            .mobile-big-logo{
                display: none;
            }
            .mobile-small-logo{
                display: block;
            }
        }
    }
}
