.IR_demoDropDown{
    user-select: none;
    text-transform: capitalize;
    .dropDown-btn{
        cursor: pointer;
        padding: 10px 20px;
        background-color: $c-purple-77;
        border-radius: 10px;
        border: $c-purple-77 1px solid;
        
        .content{
            display: flex;
            justify-content: space-between;
            color: $c-white;

            .selectedWidget{
                font-weight: 600;
                font-size: 16px;
                line-height: 25px;
            }
        }

        &.visible {
            border-radius: 10px 10px 0px 0px ;
        }
     }
     .dropDownContainer{
        background-color: $c-white;
        border: $c-purple-77 solid 1px;
        border-bottom: $c-purple-77 solid 15px;
        border-radius: 0px 0px 10px 10px;

        .widgetLink{
            padding: 5px 0px 5px 20px;
            cursor: pointer;
            &:hover {
                color: $c-purple-77;
                background: $c-green-e9;
            }
        }
     }

    @keyframes rotate {
            100% {
                -webkit-transform: rotate(180deg);
                transform:rotate(180deg);
            }
        }

}