.IR_dropDown{
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // align-items: center;
     color: $c-white;
     padding: 18px 0;

     a {
        cursor: pointer;
        &:visited {
             color: $c-dark-23;
            }
     }
     .blackText {
        color: $c-dark-23;
     }
     .dropDown-btn{
         display: flex;
         gap: 10px;
         cursor: pointer;
        .dd_comp_logo{
            border: $c-white 1px solid;
            border-radius: 50%;
        }
         .content{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 15px;
            gap: 8px;
            border: solid 1px $c-white;
            border-radius: 40px;
            user-select: none;

         }
         &.blackText {
            .dd_comp_logo{
                border: $c-dark-23 1px solid;
            }
             .content{
                border: solid 1px $c-dark-23
             }
         }
     }
     &.language{
        height: 28px;
        width: 72px;
        &:hover {
            .rotateArrow{
                animation: rotate .3s ease-out;
                animation-fill-mode: forwards;
            }
        }
        .dropDown-btn{padding-left: 20px;}
        .dropDownContainer{
            z-index: 8080;
            position: relative;
            top: 10px;
            padding: 13px 55px 20px 20px;
            box-shadow: 0px 4px 20px rgba(12, 10, 97, 0.45);
            border-radius: 20px;
            width: fit-content;
            background-color: $c-white;

            .listItem{
                display: flex;
                gap: 15px;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
                cursor: pointer;
                align-items: center;
                
                span.listItem-imageWrapper{
                    height:fit-content;
                }
                span.listItem-title {
                    color: $c-dark-23;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 36px;
                    &.selected {
                        font-weight: 600;
                    }
                }
                &:hover {
                    span.listItem-title {
                    color: $c-green-20;
                    }
                }
            }
        }
    }

    //USER SETTINGS DROPDOWN
    &.settings{
        height: 40px;
        &:hover {
            .rotateArrow{
                animation: rotate .3s ease-out;
                animation-fill-mode: forwards;
            }
        }
        .dropDownContainer{
            z-index: 8080;
            position: absolute;
            align-items: flex-end;
            top: 70px;
            padding: 13px 55px 20px 20px;
            box-shadow: 0px 4px 20px rgba(12, 10, 97, 0.45);
            border-radius: 20px;
            width: fit-content;
            background-color: $c-white;
            display: flex;
            flex-direction: column;

            @include for-phone{
                top: 60px;
            }

            .dropDown-btn{
                border: $c-white 1px solid;
            }

            .listItem{
                display: flex;
                gap: 15px;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
                // a:span:hover {
                //     color: $c-green-20;
                // }
                cursor: pointer;
                align-items: center;

                span.listItem-imageWrapper{
                    height:fit-content;
                }
                span.listItem-title {
                    color: $c-dark-23;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 36px;

                    
                    &.selected {
                        font-weight: 600;
                    }
                }
                &:hover {
                    .listItem-title{
                        color: $c-green-20;
                    }
                    .listItem-image {
                        color: $c-green-20;
                    }
                }
            }
        }
    }
    @keyframes rotate {
            100% {
                -webkit-transform: rotate(180deg);
                transform:rotate(180deg);
            }
        }

}