.IR_paragraphWithImage{
    display: flex;
    max-width: 1134px;
    align-items: center;
    font-family: 'poppins';
    
    .IR_button {
        margin-top: 25px;
    }

    &.reverse {
        flex-direction: row-reverse;
        column-gap: 60px;
        @include for-tablet {
            flex-direction: column;
        }
        @include for-phone {
            .title-description-wrapper{
                margin-left: 0px;
                margin-bottom: 46px;
            }
            div.image {
                width: 110%;
            }
        }
        
    }

    .title-description-wrapper{
        margin-right: 62px;
        flex: 1;
        h5.info {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: $c-green-20;
        }
        h3.title {
            font-weight: 700;
            font-size: 36px;
            line-height: 46px;
            color: $c-purple-77;
        }
        span.description {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: $c-dark-10;
            p{
                margin-top: 15px;
                max-width: 553px;

                @include for-phone {
                    margin-top: 10px;
                }
            }
        }
    }
    .browserImage{
        filter: drop-shadow(0px 4px 20px rgba(12, 10, 97, 0.45));
        border-radius: 10px;
    }
    div.image{
        flex: 1;
        > img {
            width: 100%;
        }
    }
    @include for-tablet {
        flex-direction: column;
        row-gap: 40px
    }

    @include for-phone {
        flex-direction: column;

        .title-description-wrapper{
            margin-right: 0px;
            margin-bottom: 46px;
            h5.info {
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
            }
            h3.title {
                font-size: 28px;
                line-height: 36px;
            }
            span.description {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}