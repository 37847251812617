.toastContainer {
    position: fixed;
    top: 105px;
    right: 10px;
    z-index: 999999;
    .toast {
        .KCL_alert {
            margin: 0;
        }
    }
}