// /* Fonts */
// $font-default: 'Open Sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $font-primary: 'Source Sans Pro', sans-serif;
// $font-secondary: 'Poppins', sans-serif;

// /* Colors */
// /* The *-rgb color names are simply the RGB converted value of the corresponding color for use in the rgba() function */

// /* Default text color */
// $color-default: #10132e;

// /* Defult links color */
// $color-links: #0ea2bd;
// $color-links-hover: #1ec3e0;

// /* Primay colors */
// $color-primary: #209aa0;
// $color-primary-light: rgba(32, 154, 160, 0.4);

// /* Secondary colors */
// $color-secondary: #485664;
// $color-secondary-light: #8f9fae;
// $color-secondary-dark: #3a4753;

// $c-purple-77: #7720a0;
// $color-footer: #E9F5F5;
// $color-footer-secondary: #232530;
// $color-footer-secondary-text: #f2f2f2;
// $color-input-background: #f8f8f8;
// $color-input-border: #cfcfcf;
// $color-input: #525252;
// $color-login-background: #313131;
// $bg-disabled-color: rgba(0, 0, 0, .26);

// /* General colors */
// $color-white: #ffffff;
// $color-black: #000000;

// /* Widths */
// $nav-container-width: 100%;

//CUSTOM COlORS
$c-white: #FFFFFF;

$c-green-20: #209AA0;
$c-green-e9: #E9F5F5;
$c-green-df: #DFF0F0;
$c-purple-66: #6638A0;
$c-purple-77: #7720A0;
$c-gray-f8: #f8f8f8;
$c-gray-bd: #BDBEC1;
$c-gray-cf: #cfcfcf;
$c-gray-d9: #D9D9D9;
$c-gray-39: #393A45;
$c-gray-9d: #9DA2A8;
$c-gray-98: #989FA8;
$c-gray-f2: #f2f2f2;
$c-gray-52: #525252;
$c-gray-6e: #6E7379;
$c-dark-10: #10132E;
$c-dark-23: #232530;
$c-blue-32: #3255B1;
$c-blue-00: #0074e8;
$c-pink-f0: #F0EAF3;
$c-red-db: #DB0033;
$c-red-ffd: #FFD6E0;
$c-red-ff: #FFEFF3;

@mixin for-phone {
	@media (max-width: 640px) { @content; }
}
@mixin for-tablet {
	@media (max-width: 1010px) { @content; }
}

//screen sizes
$breakpoints: (
	mobile-small: 374px,
	mobile-smedium: 474px,
	mobile-medium: 575px,
	mobile: 767px,
	tablet: 1023px,
	small-desktop: 1199px,
	medium-desktop: 1439px,
	medium-large-desktop: 1540px,
	large-desktop: 1640px,
	very-large-desktop: 2160px
);
