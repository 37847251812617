.IR_irWidgets{
    background: $c-green-e9;
    .container{
        min-height: 100vh;
        @include for-phone {
            .noSidePadding{
                padding-right: 0;
                padding-left: 0;
            }
        }
        div.toggleWrapper{
            margin-bottom: 30px;
            display: flex;
            width: fit-content;
            column-gap: 10px;
            padding-left: 10px;
            @include for-phone {
                padding-left: 32px;
            }

            label.viewType{
                font-weight: 400;
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: $c-dark-23;
            }
            span.allwidgets{
                font-weight: 400;
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: $c-dark-23;
            }
        }
        .headerTitles{
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            padding-left: 25px;
            @include for-phone {
                display: none;
            }
            .widgetNameHeader, .widgetDescriptionHeader{
                flex: 1;
                font-weight: 400;
                font-size: 12px;
                line-height: 25px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: $c-dark-23;
            }
            .widgetDescriptionHeader{
                flex: 2;
            }
        }

        .contentWrapper{
            padding-bottom: 242px;
            .box{
                background: $c-white;
                border-radius: 10px;
                margin-bottom: 2px;
                min-height: 50px;
                display: flex;
                align-items: center;
                padding: 0 25px;
                cursor: pointer;
                @include for-phone {
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 7px; 
                    padding-bottom: 7px;
                    border-radius: 0;
                }
                span.widgetName{
                    flex: 1;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 34px;
                    color: $c-dark-23;
                    text-transform: capitalize;
                }
                span.widgetDescription{
                    flex: 2;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $c-dark-23;
                }

                &:hover {
                    filter: drop-shadow(0px 4px 30px rgba(32, 154, 160, 0.2));
                }
            }
        }

        //CARD VIEW
        .cardViewWrapper{
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            .cardViewContainer{
                display: flex;
                flex-wrap: wrap;
                column-gap: 20px;
                row-gap: 60px;
                justify-content: center;
                padding-bottom: 100px;
                user-select: none;
                
                .card{
                    flex-basis: 25%;
                    color: $c-dark-23;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    max-width: 322px;
    
                    &:last-child{
                         div.imageContainer {
                            background: $c-green-df;
                         }
                    }
                    .titleWrapper{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        span.widgetName{
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 34px;
                            text-transform: capitalize;
                        }
                        span.widgetDescription{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            padding-bottom: 15px;
                        }
                    }
    
                    div.imageContainer{
                        width: 322px;
                        height: 322px;
                        background: $c-white;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        > img{
                            border-radius: 10px;
                        }
                    }
    
                    .fa-icon{
                        color: $c-green-20;
                        margin-right: 12px;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 46px;
                        display: none;
                    }
                    &:hover {
                        .fa-icon{
                            display: inline-block;
                        }
                        div.imageContainer{
                            filter: drop-shadow(0px 4px 20px rgba(32, 154, 160, 0.3));
                        }
                    }
                }
    
                @include for-phone {
                    padding-bottom: 0px;
    
                    .card{
                        max-width: 165px;
                        .titleWrapper{
                            .widgetName{
                                font-size: 16px;
                                line-height: 24px;
                            }
                            span.widgetDescription{
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                        div.imageContainer{
                            height: 165px;
                            width: 165px;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}