.IR_priceCard{
    flex: 1 3;
    background-color: white;
    border-radius: 20px;
    padding: 40px 50px;
    font-family: 'Poppins';
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
        // margin-top: 7px;
        font-weight: 700;
        font-size: 46px;
        line-height: 46px;
        color: $c-purple-77;
        white-space: pre;
        small {
            font-size: 14px;
            margin-left: 9px;
        }
    }

    ul{
        margin-left: -25px;
        li{
            font-weight: 400;
            font-size: 16px;
            line-height: 36px;
            list-style-position: outside;
            white-space: pre;

            &::marker{
                color: $c-green-20;
            }
        }
    }

    @include for-tablet {
        flex-grow: 1;
        height: fit-content;
        padding: 40px 30px 30px;

        ul {
            margin-bottom: 30px;
            margin-top: 5px;
        }
    }
    @include for-phone {
        h4 {
            font-size: 40px;
            small {
                font-size: 12px;
            }
        }

        ul {
            li {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
}