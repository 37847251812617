.IR_numberInput {
    display: flex;
    flex: 1;
    border: 1px solid $c-gray-cf;
    border-radius: 60px;
    padding: 10px 25px;
    label {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #525252;
        position: absolute;
        padding: 0 2px;
        background-color: #FFF;
        margin-left: 23px;
        transform: translate(-23px, -18px);
        white-space: pre;
    }
    &:focus-within {
        outline: $c-blue-00 solid 1px;
        label {
            color: $c-blue-00;
        }

        .iconWrapper .faIcon {color: $c-blue-00;}
    }
    input[type=text]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        opacity: 0;
      }
    
    input[type=text] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 
        border: none;
        flex: 1;
        outline-width: 0;
        outline: none;
    }


    .iconWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        .faIcon{
            cursor: pointer;
            color: $c-dark-23;
            &:not(:last-child){
                margin-bottom: -6px;
            }
        }
    }

    &.disabled {
        
        .faIcon{
            color: $c-gray-9d;
            cursor:default;
        }
    }
}