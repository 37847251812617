.IR_button {
    max-width: fit-content;
    background: $c-white;
    color: $c-white;
    padding: 5px 20px;
    border-radius: 40px;
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    width: fit-content;
    white-space: pre;
    cursor: pointer;
    user-select: none;
    &.onTop {
        z-index: 999;
    }
    @include for-phone {
        font-size: 13px;
    }
    
    &.with-icon {
        .icon-fa {
            margin-left: 10px;
        }
    }
    
    &.primary {
        background: $c-green-20;
        border: 1px solid $c-green-20;

        &:hover{
            border: $c-green-20 solid 1px;
            background: $c-white;
            color: $c-green-20;
        }
    }

    &.secondary {
        background: $c-white;
        border: 1px solid $c-green-20;
        color: $c-green-20;

        &:hover{
            border: $c-green-20 solid 1px;
            background: $c-green-20;
            color: $c-white;
        }
    }
    &.reverseTransparent {
        color: $c-green-20;
        border: $c-white 1px solid;
        &:hover{
            background: transparent;
            color: $c-white;
        }
    }

    &.submit {
        position: relative;
        background: linear-gradient(90.86deg, #7720a0 7.01%, #209aa0 99.52%) right;
        z-index: 1;
        color: $c-white;
        border: none;
          
        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(270.86deg, #7720a0 7.01%, #209aa0 99.52%) bottom;
            z-index: -1;
            transition: opacity 0.5s linear;
            opacity: 0;
            border-radius: 40px;
        }
        
        &:hover::before {
            opacity: 1;
            border-radius: 40px;
        }
          
    }

    &.transparent {
        background: transparent;
        border: $c-white solid 1px;
        &:hover {
            background-color: $c-white;
                color: $c-green-20;

        }
    }

    &.whiteAndBlue {
        background: $c-white;
        color: $c-blue-32;
        border: $c-white solid 1px;

        &:hover {
            background: transparent;
            color: $c-white;
        }

    }

    &.lg {
        max-height: 55px;
        padding: 16px 50px;
        font-size: 18px;
        @include for-phone {
            font-size: 15px;
        }
    }
    &.md {
        max-height: 35px;
    }
    &.sm {
        max-height: 25px;
    }
}