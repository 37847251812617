.footer {
  padding: 0;
  font-family: 'Poppins';
  font-style: normal;

  a:not(.IR_button) {
    text-decoration: none;
    color: $c-dark-23;
  }

  .primaryFooter{
    padding: 66px 15px 71px;
    background-color: $c-green-e9;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .primaryFooter-content{
      flex-basis: 100%;
      max-width: 1366px;
      display: flex;
      justify-content: space-between;
      .contactInfo-container{
        .logo{
          margin-bottom: 25px;
        }
        a.address {
          cursor: pointer;
          display: flex;
        }
        .phone-email {
          margin-top: 35px;
          margin-bottom: 0;
          white-space: pre;
          > span > img {
            margin-right: 10px;
          }
          .phone{
            margin-right: 57px;
          }
        }
      }
      .linksAndLoginWrapper{
        display: flex;

        @include for-tablet {
          flex-direction: column;
        }
        .linkContainer{
          display: flex;
          flex-direction: row;
          white-space: pre;
          .linkWrapper{
            display: flex;
            flex-direction: column;
            font-weight: 600;
            font-size: 15px;
            line-height: 25px;
            &:first-child{
              margin-right: 137px;
            }
            >a{
              margin-bottom: 30px;
              &:hover {
                color: $c-green-20;
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
  
        .loginButtonContainer{
          display: flex;
          align-items: flex-end;
          padding: 0px 112px 0 146px;
          @include for-tablet {
            padding: 0;
            justify-content: center;
          }
        }
      }
    }
  } 
  .secondaryFooter{
    background-color: $c-dark-23;
    @include for-phone {
      font-size: 11px;
      line-height: 14px;
    }
    display: flex;
    justify-content: center;
    padding: 15px 40px;
    .secondaryFooter-container{
      justify-content: space-between;
      display: flex;
      flex-basis: 100%;
      max-width: 1366px;
      .left {
        display: flex;
        .prik {
          margin-left: 12px;
          margin-right: 12px;
        }
        br {
          display: none;
        }
        > a {
          font-weight: 600;
          font-size: 13px;
          line-height: 25px;
        }
        @media (max-width:860px) {
          flex-direction: column-reverse;
          .prik {
            display: none;
          }
          p {
            br {
              display: block;
            }
          }
        }
      }

      p {
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
      }

      color: $c-gray-f2;
      a {
        text-decoration: none;
        color: $c-gray-f2;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        @include for-phone {
          font-size: 13px;
        }

        &.terms{
          margin-right: 30px;
          @include for-phone {
            margin-right: 20px;
          }
        }
      }
    }
  }


  //mobile footer
  @include for-tablet {
    .primaryFooter{
      padding: 53px 15px 50px;

      .primaryFooter-content{
        flex-direction: column;
        align-items: center;
        .contactInfo-container{
          align-items: center;
          display: flex;
          flex-direction: column;

          margin-bottom: 50px;
        }


        .loginButtonContainer {
          .IR_button{

            margin-top: 25px;
          }
        }
      }
    }
  }

  @include for-phone {
    .secondaryFooter{padding: 15px;}
    div.linkContainer > .linkWrapper:first-child {
      margin-right: 38px !important;
    }
    .address { text-align: center;}
    a {
      font-size: 13px;
    }
    .phone-email{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0px !important;
      .phone {

        margin-right: 0 !important;
        margin-bottom: 12px;
        margin-top: 15px;
      }
    }
  }
}
