.IR_termsPage {
    .headSectionWrapper{
        padding: 115px 156px 10px;
        // height: 350px;
        background: $c-gray-f2;
        @include for-phone {
            padding-right: 15px;
            padding-left: 15px;
         }
    }

    .pageWrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 45px;
        @include for-phone {
            padding-bottom: 0px;
        }
        > div {
            flex: 1;
            max-width: 900px;
            margin-bottom: 40px;

            &.disclaimer{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;

                @include for-phone {
                    font-size: 14px;
                    line-height: 24px;
                 }
            }
            &.section{
                
                h3 {
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 46px;
                    color: $c-purple-77;

                    @include for-phone {
                        font-size: 28px;
                        line-height: 38px;
                     }
                }
                h4 {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 38px;
                    color: $c-purple-77;

                    @include for-phone {
                        font-size: 22px;
                        line-height: 30px;
                     }
                }
                > ol.list {
                    padding-left: 25px;
                    ::marker {
                        content: '';
                    }
                    li{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        @include for-phone {
                            font-size: 14px; 
                         }
                        &.listItem {
                            counter-reset: none;
                            padding-left: 20px;
                            position: relative;
                            counter-increment: item;
                            margin-bottom: 15px;
    
                            &:before {
                                content: counter(item);
                                color: $c-white;
                                position:absolute;
                                left:-25px;
                                top: -1px;
                                background-color:$c-green-20;
                                z-index: -1;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 26px;

                                @include for-phone {
                                   font-size: 12px; 
                                }
                            }
                            > ol {
                                padding-left: 25px;
                                padding-top: 5px;
                                counter-reset: item;
                                ::marker {
                                    content: '';
                                }
                                li {
                                    position: relative;
                                    counter-increment: item;
                                    margin-bottom: 15px;
                                    padding-left: 5px;
                                    &:before {
                                        content: counter(item, upper-alpha) ')';
                                        color: $c-green-20;
                                        position:absolute;
                                        left:-25px;
                                        z-index: -1;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 14px;
                                        line-height: 26px;

                                        @include for-phone {
                                            font-size: 12px; 
                                         }
                                    }
                                    &:last-child{
                                        padding-bottom: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}