.IR_interestedModal {
    max-width: 1094px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(96.32deg, $c-green-20 61.79%, $c-purple-77 91.69%);
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    padding: 52px 20px 70px;
    flex-basis: 100%;

    > h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        color: $c-white;
        margin-bottom: 40px;
        text-align: center;
    }
    .buttonWrapper{
        display: flex;
        align-items: center;
        > span {
            font-weight: 600;
            font-size: 15px;
            line-height: 25px;
            color: $c-white;
            margin-right: 20px;
            margin-left: 20px;
            @include for-phone{
                margin-right: 10px;
                margin-left: 10px;
               
            }
        }

        @media (max-width: 340px) {
            flex-direction: column;
            align-items: center;
        }
    }
    @include for-phone{
        padding: 88px 37px 100px;
        h3 {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 20px;
        }
    }
}