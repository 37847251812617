#header{
  width: 100%;
  position: fixed;
  .IR_burger{
    display: none;
  }
  .logo {
    z-index: 999;
  }
  #navbar{
    padding: 0;
    display: flex;
    align-items: center;
    div.burgerWrapper {
      margin-right: 15px;
    }
    > ul{
      &.navLinkContainer {
        display: flex;
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0px;
  
        @include for-tablet {
          display: none;
        }
      }
      &.loginButton {
        display: none;
        margin-right: 5px;
        list-style: none;
        @include for-tablet {
          display: inline-block;
        }
      }
    }
  }
  .fa-icon.faLock{margin-left: 5px}
  .headerContent{ 
    padding: 0 37px;

    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-phone {
      padding: 0 15px;
      height: 67px;
    }
  }
  
  &.header{
    z-index: 997;
  &.sticked {
    background: $c-white;
    box-shadow: 0px 2px 20px rgba(72, 86, 100, 0.1);
    height: 90px;
    position: fixed;
  
    .fa-icon {
      .faLock{
        margin-left: 5px;
      }
    }
  }

  &.isAuthenticated{
    background: #232530;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
  .authenticatedButtons-wrapper{
    // border: red solid 1px;
    display: flex;
    gap: 30px;
    margin-left: -15px;
    z-index: 999;
    align-items: center;
  }
  .whiteText{
    color: $c-white;
  }
  ul.navLinkContainer{
    margin-right: 35px;

    li {
      padding: 3px 0;
    }

    div.prik {
      border-right: $c-dark-23 1px solid;
      margin-left: 22px;
      &.blackPrick{
        border-right: rgba(255, 255, 255, 0.4) 1px solid;
      }
    }
  }
}}

div.mobileMenuContainer{
  display: none;
}

.nav-link:hover {
  color: $c-green-20;
}

.nav-link:before {
  background-color: $c-green-20;
}

.nav-link{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;

  padding: 4px 3px;
  margin: -4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  font-size: 15px;
  font-weight: 400;
  color: $c-white;
  white-space: nowrap;
  position: relative;
  width: fit-content;

  color: #232530;

  &.authenticatedLink{
    margin-left: 22px;
  }
  &.active-link {
    color: $c-purple-77;
    line-height: 25px;
    cursor: default;
    span.mobileMenuLink{
      color: $c-white;
    }
    &::after{
      width: 100%;
    }
  }
  &:after {
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    width:0;
    height:3px;
    border-radius: 10px;
    background-color: $c-green-20;
    transition: width .3s;
  }
}
.nav-link:hover:after  {
  width: 100%;
}

@include for-tablet{
  #header {
    .IR_burger{
      display: block !important;
      height: 50px;
      z-index: 999;
    }
    &.header.mobile {
      box-shadow: none;
      background: transparent;
      .headerContent{
        padding: 0px 15px 15px;
      }
    }
  }

  div.mobileMenuContainer.active {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(175.28deg, #232530 79.34%, #209AA0 103.66%);
    z-index: 919;
    padding-top: 120px;

    > ul.navLinkContainer {
      display: flex;
      flex-direction: column;
      color: $c-white;
      list-style: none;
      padding-left: 20px;
      > li {
        margin-bottom: 30px;
        span.mobileMenuLink {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 0;
          color: white;

          .fa-icon{
            margin-left: 6px;
          }
        }
      }
    }
  }
}

@include for-phone {
  #header.header.sticked{
    height: 67px;
  }
}
