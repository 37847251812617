.IR_colorPicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;

    &.disabled{
        opacity: 0.5;
        cursor: default;
    }

    label {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: $c-dark-23;
        user-select: none;
        // white-space: pre;
        margin-right: 7px;
    }

 

    .colorPickerBtn{
        background: $c-white;
        border: 2px solid $c-gray-bd;
        border-radius: 40px;
        padding: 3px 12px 3px 3px;
        display: flex;
        gap: 10px;
        .selectedColor{
            width: 27px;
            height: 27px;
            border-radius: 40px;
        }
        .text{
            font-weight: 600;
            font-size: 15px;
            line-height: 26px;
            color: $c-dark-23;
        }
    }

    .colorPickerModule_wrapper {
        position: absolute;
        transform: translate(0px, -190px);

        @media (max-width:1440px){
            right: 5px;
        }
    }

    @media (max-width: 1440px) {
        
    }
}