// .react-toggle{
//     .react-toggle-track{
//         height: 30px;
//         width: 68px;
//     }
//     .react-toggle-thumb{
//         background-color: $c-green-20;
//     }

// }


.react-toggle-track {
    position: relative;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 68px;
    background-color: $c-dark-23;

    img {
        opacity: 1;
        position: absolute;
        z-index: 98;
        top: -2px;
        right: -1px;
        width: 14px;
        height: 14px;
    }
}

.react-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
}
.react-toggle-thumb {
    top: unset;
    left: -9px;
    width: 34px;
    height: 34px;
    background-color: $c-green-20;
    border: none;
}
.react-toggle--focus {
    .react-toggle-thumb{
        box-shadow: none;
    }
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
    display: block;
    position: absolute;
    z-index: 99;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 1;
}

.react-toggle--checked .react-toggle-track {
    background-color: $c-dark-23;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $c-dark-23;
}

.react-toggle-track-check {
    opacity: 1;
}