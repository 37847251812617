.IR_homePage{
  div.hero {
    padding-top: 90px;
    @include for-phone {
      padding-top: 67px;
    }
    width: max(100vw, 100%);
    height: max(100vh, 100%);
    // background: conic-gradient(from 180deg at 50% 50%, $c-purple-77 0deg, $c-green-20 129.37deg, $c-purple-77 360deg);
    color: white;
    text-align: center;
   
      background: linear-gradient(270deg, $c-purple-77, $c-green-20);
      background-size: 150% 150%;
  
      -webkit-animation: animatedLinearBackground 5s ease infinite;
      -moz-animation: animatedLinearBackground 5s ease infinite;
      animation: animatedLinearBackground 5s ease infinite;
  
  @-webkit-keyframes animatedLinearBackground {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes animatedLinearBackground {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes animatedLinearBackground {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }

    div.hero-header{
      h1 {
        font-weight: 700;
        font-size: 60px;
        line-height: 90px;
        @include for-phone {
          font-size: 34px;
          line-height: 40px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        @include for-phone {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    img.hero_image{
      width: 80%;
      max-width: 1285px;
      // margin-right: 15px;
    }
    p.sub-text{
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      margin-top: 88px;
      padding-bottom: 130px;
      @include for-phone {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  //first section
  .build-integrate{
    .leftCube {
      position: absolute;
      left: 453.81px;
      top: 1463px;
      transform: rotate(90deg);
    }

    .rightCube{
      position: absolute;
      left: 1395px;
      top: 1198px;
    }
    .build-integrate-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Poppins';
      font-style: normal;
      padding: 180px 153px 260px;

      > h4 {
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        text-align: center;
        color: $c-dark-10;
      }

      > h3 {
        font-weight: 700;
        font-size: 60px;
        line-height: 90px;
        text-align: center;
        letter-spacing: -0.06em;
        background: linear-gradient(89.96deg, $c-green-20 30.32%, $c-purple-66 69.81%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .description-container{
        margin-bottom: 40px;
        > h5 {
          font-weight: 300;
          font-size: 30px;
          line-height: 45px;
          text-align: center;
          color: $c-dark-10;
        }

        p{
          font-size: 21px;
          line-height: 43px;
          text-align: center;
          color: $c-dark-10;
        }
      }

      @include for-phone {
          padding: 133px 15px 0px;
          > h4 {
            font-size: 20px;
            line-height: 30px;
          }
          > h3 {
            font-size: 34px;
            line-height: 51px;
          }
          .description-container{
            margin-bottom: 30px;
            > h5 {
              font-size: 18px;
              line-height: 28px;
            }

            p{
              font-size: 15px;
              line-height: 33px;
            }
          }
        }
    }
  }

  //paragraphs styling
  .paragraphWrapper {
    margin: 38px 53px 100px 153px;
    @include for-tablet {
      margin: 70px 18px 100px 15px;
    }
  }
  //interested modal
  .interestedSection {
    margin: 100px 15px;
    // margin-top: 100px;
    // margin-bottom: 100px;
    @include for-phone{
      // padding: 0 15px;
      margin-bottom: 60px;
      margin-top: 0px;
    }
  }
}

