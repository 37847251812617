.IR_selectDropDown{
    user-select: none;
    flex-basis: 100%;

    .selectedItem{
        background: #FFFFFF;
        border: 1px solid $c-gray-cf;
        border-radius: 30px;
        padding: 0px 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        cursor: pointer;
        white-space: pre;

        &.visible{
            border-radius: 24px 24px 0px 0px;
            border-bottom: $c-white 1px solid;
        }
    }
    label {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: $c-gray-52;
        position: absolute;
        background-color: #FFF;
        margin-left: 23px;
        transform: translate(0px,-54px);
        white-space: pre;
    }

    .dropDownContainer {
        flex-basis: 100%;
        background: $c-white;
        // padding-left: 23px;
        max-height: 200px;
        overflow-y: auto;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 -2px -5px 0 rgba(0, 0, 0, 0.26);
        border: 1px solid $c-gray-cf;
        position: absolute;
        z-index: 99;
        border-radius: 0px 0px 24px 24px ;

        .dropdown_item {
            padding: 5px 0 5px 23px;
            font-size: 15px;
            line-height: 25px;
            
            img {
                border-radius: 50%;
            }

            &.selected {
                background: $c-green-e9;
                cursor: default !important;
            }

            &:hover {
                background: $c-green-e9;
                cursor: pointer;
            }
        }
    }
    &.language{
        .selectedItem{
            span {
                align-items: center;
                justify-content: center;
                display: flex;
                column-gap: 15px;
            }
        }
        .dropDownContainer {
            .dropdown_item {
                display: flex;
                column-gap: 15px;
                img {
                    border-radius: 50%;
                }
            }
        }
    }
}
