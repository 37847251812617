.IR_login {
    background: $c-green-e9;
    height: 100vh;
    .page_content{
        // padding-top:290px;
        // margin-top: 90px;
        height: 100%;
        @include for-phone {
           padding-top: 67px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        .loginModal {
            margin: auto;
            margin-top: 250px;
            max-width: 338px;
            width: 100%;
            padding: 45px 50px 50px;
            border-radius: 20px;
            background: $c-white;


            @include for-phone { 
                margin-top: 150px;
                margin-right: 15px;
                margin-left: 15px;
            }

            form.login-form {
                .imageWrapper{
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    > img.logo {
                        width: fit-content;
                        margin-bottom: 55px;
                    }
                }
                .inputField {
                    margin-bottom: 25px;

                }
                .checkboxWrapper{
                    margin: -10px 0px 33px 15px;
                    width: 'fit-content';
                }
            }
    
            > * {
                width: 100%;
            }
        }

        .footerWrapper{
            bottom: 0px;
        }
    }
}