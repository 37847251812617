.IR_pricingPage{
    // overflow-x: hidden;
    background: linear-gradient(180.02deg, #209AA0 30.9%, #232530 46.53%);
    .priceCardWrapper{
        display: flex;
        gap: 25px;
        align-items: stretch;
        height: 395px;
        width: 100%;
        
    }

    .smallPrintContainer{
        display: flex;
        margin-top: 50px;
        padding-bottom: 100px;
        width: 100%;
        
        @include for-tablet{
            justify-content: center;
        }
        @include for-phone {
            padding-bottom: 0px;
        }
        .smallPrintWrapper{
            display: flex;
            max-width: 1366px;
            gap: 140px;

            .smallPrint{
                display: flex;
                flex: 1;
                gap: 35px;
            }

            h4{
                color: $c-white;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $c-white;
                @include for-phone{
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
    @include for-tablet {
        .priceCardWrapper{
            flex-direction: column;
            gap: 30px;
            height: 100%;
        }
        .smallPrintContainer{
            .smallPrintWrapper{
                flex-direction: column;
                gap: 40px;
                .smallPrint{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;

                    .post-title-description{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        h4 {
                            margin: 0px;
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
}