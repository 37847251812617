.IR_demos{
    .widgetButtons{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 10px;
        row-gap: 15px;

        @include for-phone {
            display: none;
        }
    }

    .dropDownWrapper{
        display: none;
        @include for-phone {
            display: block;
        }
    }
    .widgetWrapper {

        margin-bottom: 100px;
    }
    div.toggleWrapper{
        margin-bottom: 30px;
        display: flex;
        padding-left: 10px;
        justify-content: center;
        @include for-phone {
            padding-left: 32px;
        }
        label.viewType{
            font-weight: 400;
            font-size: 16px;
            line-height: 34px;
            letter-spacing: 0.06em;
            color: $c-white;
        }
        .versionCheck{
            top: 5px;
            z-index: 999;
            position:absolute;

            &.v2{
                right: -5px;
            }
        }
    }

    .demoDisclaimer{
        background: rgb(152,159,168, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        max-width: 1084px;
        padding: 20px 31px 15px 18px;
        column-gap: 30px;
        row-gap: 20px;

        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        
        margin-top: 50px;
        margin-bottom: 60px;

        @include for-phone{
            margin-top: 45px;
            margin-bottom: 40px;
            flex-direction: column;
            padding: 18px 15px 25px;
            font-size: 14px;
            line-height: 24px;
        }
        .fa-icon{
            margin-right: 7px;
            position: relative;
            color: #989FA8;
        }
    }
}