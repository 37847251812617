.IR_errorPage {
    background: conic-gradient(from 180deg at 50% 50%, $c-purple-77 0deg, $c-green-20 129.37deg, $c-purple-77 360deg);
    width: 100vw;
    height: 100vh;
    color: $c-white;

    div.headerWrapper {
        display: flex;
        align-items: center;
        flex-wrap:wrap;
        h1
        {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: white;
            color: transparent;
            font-weight: 700;
            font-size: 80px;
            line-height: 120px;

        }
        
    }
    h3.error-message {
        font-weight: 700;
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 15px;

        p.reverseLetter{
            display: inline-block;
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
        }
    }

    p.error-detail-message {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    div.navigationWrapper {
        display: flex;
        align-items: center;
        gap: 30px;

        > a {
            &.whiteLink {
                color: $c-white;
            }
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            .fa-icon{
                margin-left: 10px;
            }
        }
    }


    @include for-phone {

        > .content {
            justify-content: center;
        }

        div.headerWrapper {
            flex-direction: column;
            h1{
                &.error-code {
                    font-weight: 700;
                    font-size: 60px;
                    line-height: 90px;
                    text-align: center;
                }
                &.error-title {
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 44px;
                    text-align: center;
                }
            }
            margin-bottom: 12px;

        }
        h3.error-message {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            text-align: center;
        }
        p.error-detail-message {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-align: center;

            .breakpoint {
                display: none;
            }
        }

        div.navigationWrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
    
            > a {
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
}