.IR_headSection{
    margin-bottom: 40px;
    padding-right: 37px;
    padding-left: 37px;
    @include for-phone{
        padding-right: 15px;
        padding-left: 15px;
    }
    // THEME
    // default: white
    h5,h3,h1,p {
        color: $c-white;
    }
    //THEME purple and green 
    &.greenAndPurple {
        h5 {
            color: $c-green-20;
        }

        h1 {
            color: $c-purple-77;
        }
        p {
            color: $c-dark-10;
        }
    }
    h1 {
        // margin-top: -11px;
        @include for-phone {
            margin-top: 0px;
            width: auto;
        }
    }
    h2 {
        font-weight: 700;
        font-size: 60px;
        line-height: 90px;

        text-align: center;
        color: $c-white;

        @include for-phone {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 20px;
        }
    }

    p {
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        
        text-align: center;
        color: $c-white;
        margin-top: 15px;
        
        @include for-phone {
            width: auto;
            margin-top: 10px;
            font-size: 18px;
            line-height: 28px;
        }
        &.halfWidth{
            width: 670px;
        }
        @media (max-width: 670px) {
            &.halfWidth{
                width: auto;
            }
        }
    }

    .buttonWrapper{
        margin-top: 40px;
    }
}