.IR_activeButton{
    padding: 4px 15px;

    background-color: $c-white !important;
    border: 1px solid $c-purple-77;
    border-radius: 8px;

    font-weight: 600;
    font-size: 13px;
    line-height: 25px;
    color: $c-purple-77;
    text-transform: capitalize;

    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    transition: color 0.15s, background 0.15s;

    &.active {
        background-color: $c-purple-77 !important;
        color: $c-white;
        cursor: default;
    }

    &:hover {
        background-color: $c-purple-77 !important;
        color: $c-white;
    }

}