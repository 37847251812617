.IR_smallPrint {
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    div.title-description {
        color: white;
        margin-left: 27px;
        max-width: 404px;
        margin-top: 10px;
       
        .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            margin-bottom:3px;
            @include for-phone {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 8px;
            }
        }
        a {
            text-decoration: none;
            color: white;
        }
    }
    @include for-tablet{
        &.top{

            flex-direction: column;
            align-items: center;
            .image img {width: 135px;}
            div.title-description {
                width:auto;
                align-items: center;
                text-align: center;
                .title {
                    margin-bottom: 10px;
                }
            }
        }
    }
    
    @include for-phone {
        font-size: 14px;
        line-height: 24px;
        div.title-description {
            .title {
                font-size: 20px;
                line-height: 30px;
            }
            p {}
        }
        &.top{
            display: flex;
            flex-direction: column;
            align-items: center;

            
            div.title-description {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 0;
                margin-top: 20px;
                text-align: center;

                p{ margin-bottom: 0}
            }
        }
    }
}