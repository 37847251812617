.IR_checkbox{
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  user-select: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: $c-gray-39;
  //unchecked
  .box{
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border: 1px solid $c-gray-bd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    
  }
  label {
    text-transform: capitalize;
    cursor: pointer;
  }

  //checked
  &.checked{
    .box{
      font-weight: 900;
      font-size: 15px;
      line-height: 26px;
      box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
    
        /* or 173% */
        display: flex;
        align-items: center;
        text-align: center;

        .fa-icon {
          width: 100%;
          height: 100%;
        }
    }
    &:not(.rememberMe){
      color: $c-green-20;
      .box{
        background-color: $c-green-20;
        border-color: $c-green-20;
        .fa-icon {
          color: $c-white;
        }
      }
    }

  }

  &.rememberMe{
    color: $c-gray-52;
    .box{
      width: 16px;
      height: 16px;
    }

    &.checked{
      .fa-icon {
        color: $c-dark-23;
      }
    }
  }
}