.IR_cookieConsentForm {
    background: $c-white;
    box-shadow: 0px 4px 20px rgba(12, 10, 97, 0.45);
    border-radius: 10px;
    position: fixed;
    bottom: 66px;
    left: 54px;
    margin-right: 54px;
    z-index: 999999;

    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;

    @include for-phone {
        left: 20px; 
        margin-right: 20px;
        bottom: 20px;
    }
        div.textContainer {
            > span {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $c-dark-23;
            }
        }
    }
